import React, { useCallback, useState } from "react"

import { withStyles, TextField, Button, Snackbar } from "@material-ui/core"
import SnackbarContent from "../../../../components/SnackbarContent"
import { useForm } from "react-hook-form"
import axios from "axios"

const Form = ({ classes }) => {
  const { register, handleSubmit, errors } = useForm()

  const [open, setOpen] = useState(null)

  const handleClose = useCallback(
    (e, reason) => {
      if (reason === "clickaway") {
        return
      }
      setOpen(null)
    },
    [setOpen]
  )

  const onSubmit = useCallback(
    data => {
      axios
        .post(`${window.location.origin}/sendMail`, { ...data })
        .then(({ data: { success } = {} }) => {
          success && setOpen("success")
        })
        .catch(e => {
          setOpen("error")
        })
    },
    [setOpen]
  )
  const hasError = Object.entries(errors).length > 0

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.name}>
        <TextField
          placeholder="Digite o nome"
          name="nome"
          label="Nome"
          variant="outlined"
          error={!!errors.nome}
          fullWidth
          inputRef={register({ required: true })}
        />
      </div>
      <div className={classes.input}>
        <TextField
          name="assunto"
          label="Assunto"
          placeholder="Digite o assunto"
          variant="outlined"
          error={!!errors.assunto}
          fullWidth
          inputRef={register({ required: true })}
        />
      </div>
      <div className={classes.input}>
        <TextField
          name="email"
          label="Email"
          placeholder="Digite o email"
          variant="outlined"
          error={!!errors.email}
          fullWidth
          inputRef={register({ required: true, pattern: /^\S+@\S+$/i })}
        />
      </div>
      <div className={classes.textArea}>
        <TextField
          name="texto"
          label="Mensagem"
          variant="outlined"
          rows="4"
          placeholder="Digite uma Mensagem"
          error={!!errors.texto}
          fullWidth
          multiline
          inputRef={register({ required: true })}
        />
      </div>
      <div className={classes.button}>
        <Button
          disabled={hasError}
          variant="contained"
          color="primary"
          type="submit"
        >
          Enviar
        </Button>
        {hasError && <p className={classes.error}>Preencha todos os campos</p>}
        {!!open && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            open={!!open}
            autoHideDuration={2000}
            onClose={handleClose}
          >
            <SnackbarContent onClose={handleClose} variant={open} />
          </Snackbar>
        )}
      </div>
    </form>
  )
}

const styles = {
  form: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    flexGrow: 0,
    maxWidth: "50%",
    flexBasis: "50%",
  },

  error: {
    color: "#f44336",
    transition: "0.6s",
    "-webkit-transition": "0.6s",
    padding: 10,
    margin: 0,
  },
  name: {
    maxWidth: "100%",
    flexBasis: "100%",
    padding: "0 15px",
    height: "min-content",
  },
  input: {
    maxWidth: "50%",
    flexBasis: "50%",
    padding: "0 15px",
    height: "min-content",
  },
  textArea: {
    maxWidth: "100%",
    flexBasis: "100%",
    padding: "0 15px",
  },
  button: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: "100%",
    flexBasis: "100%",
    height: "min-content",
    transition: "0.6s",
    "-webkit-transition": "0.6s",
    "& > button": {
      maxWidth: 200,
    },
  },
  "@media (max-width: 992px)": {
    form: {
      flex: 1,
      maxWidth: "100%",
      flexBasis: "100%",
      marginTop: 30,
      order: 2,
    },
    name: {
      padding: 15,
    },
    input: {
      padding: 15,
    },
    textArea: {
      padding: 15,
    },
  },
  "@media (max-width: 576px)": {
    input: {
      maxWidth: "100%",
      flexBasis: "100%",
    },
  },
}

export default React.memo(withStyles(styles)(Form))
