import React from "react"

import { withStyles } from "@material-ui/core"
import Image from "../images/contato.jpg"
import Container from "../components/Container"
import Banner from "../components/Banner"
import Contact from "../containers/ContactContainer"

const contato = React.memo(({ classes }) => (
  <Container title="Contato" page={3}>
    <div className={classes.root}>
      <Banner image={Image} text="Contato" />
      <Contact />
    </div>
  </Container>
))

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}

export default withStyles(styles)(contato)
