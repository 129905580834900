import React from "react"

import { withStyles } from "@material-ui/core"
import Form from "./components/Form"
import Iframe from "react-iframe"
import ContentDiv from "../../components/ContentDiv"

const ContactContainer = ({ classes }) => {
  return (
    <ContentDiv>
      <div className={classes.row}>
        <div className={classes.titleDiv}>
          <h1 className={classes.contactTitle}>Entre em contato:</h1>
        </div>
        <Iframe
          url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3950.7506190567806!2d-34.89191178469003!3d-8.024629982325772!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7ab1843f5d30ea1%3A0xdd2139c07571bdf3!2sR.%20do%20Triunfo%2C%20489%20-%20Arruda%2C%20Recife%20-%20PE%2C%2052120-090!5e0!3m2!1spt-BR!2sbr!4v1574475383795!5m2!1spt-BR!2sbr"
          className={classes.map}
        />
        <Form />
      </div>
    </ContentDiv>
  )
}

const styles = {
  map: {
    height: 450,
    border: 0,
    width: "100%",
    flexGrow: 0,
    maxWidth: "50%",
    flexBasis: "50%",
  },
  row: {
    display: "flex",
    "-ms-flex-wrap": "wrap",
    flexWrap: "wrap",
  },
  titleDiv: {
    position: "relative",
    width: "100%",
    paddingRight: 15,
    paddingLeft: 15,
    flex: "0 0 100%",
    maxWidth: "100%",
  },
  contactTitle: {
    fontWeight: 600,
    marginBottom: 20,
  },
  "@media (max-width: 992px)": {
    map: {
      flex: 1,
      maxWidth: "100%",
      flexBasis: "100%",
      marginTop: 30,
    },
    titleDiv: {
      order: 2,
    },
  },
}

export default withStyles(styles)(ContactContainer)
